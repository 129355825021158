import React from 'react';

export const features = [
  {
    id: 0,
    title: 'Instant task capture',
    image: '/img/check-circle.svg',
    cover: '4',
    content: (
      <>
        <p>
          Global shortcuts to capture tasks anywhere and integrations with your
          favourite apps (like Slack) to bring tasks in one place.
        </p>
        <video
          className="video visible-mobile"
          autoPlay
          loop
          muted
          playsInline
          preload="none">
          <source src="/videos/4.webm" type="video/webm" />
          <source src="/videos/4.mp4" type="video/mp4" />
        </video>
      </>
    ),
  },
  {
    id: 1,
    title: 'Plan and schedule tasks in one drag',
    image: '/img/calender-icon.svg',
    cover: '5',
    content: (
      <>
        <p>
          Prioritize and commit to your important tasks by scheduling them on
          your calendar.
          <br />
          &nbsp;
        </p>
        <video
          className="video visible-mobile"
          autoPlay
          loop
          muted
          playsInline
          preload="none">
          <source src="/videos/5.webm" type="video/webm" />
          <source src="/videos/5.mp4" type="video/mp4" />
        </video>
      </>
    ),
  },
  {
    id: 2,
    title: 'Simplify balance of collaboration and deep work',
    image: '/img/headphones.svg',
    cover: '6',
    content: (
      <>
        <p>
          Aerotime keeps your calendar available for meetings even when tasks
          are there, but when you hit a busy threshold it shields your time.
        </p>
        <video
          className="video visible-mobile"
          autoPlay
          loop
          muted
          playsInline
          preload="none">
          <source src="/videos/6.webm" type="video/webm" />
          <source src="/videos/6.mp4" type="video/mp4" />
        </video>
      </>
    ),
  },
];
