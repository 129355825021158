import * as React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import 'slick-carousel/slick/slick.css';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero/Hero';
import FullpageScroll from '../components/FullpageScroll/FullpageScroll';
import Metrics from '../components/Metrics/Metrics';
import Tasks from '../components/Tasks/Tasks';
import Focus from '../components/Focus/Focus';
import Info from '../components/Info/Info';
import Testimonials from '../components/Testimonials/Testimonials';
import Tools from '../components/Tools/Tools';
import Articles from '../components/Articles/Articles';
import CTA from '../components/CTA/CTA';

const IndexPage = ({
  data: {
    allGhostPost: { edges },
  },
}) => (
  <Layout>
    <SEO title="Aerotime - Be Effective, Not Busy" />
    <Hero />
    <FullpageScroll />
    <Metrics />
    <Tasks />
    <Focus />
    <Info />
    <Testimonials />
    <Tools />
    <Articles posts={edges} />
    <CTA />
  </Layout>
);

export const query = graphql`
  {
    allGhostPost {
      edges {
        node {
          title
          url
          id
          og_image
          feature_image
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.shape({
    allGhostPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default IndexPage;
