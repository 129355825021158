export default function scrollFactory({
  centerHeroCard,
  heroCardOne,
  heroCardTwo,
  heroCardThree,
  heroVideo,
}) {
  let centerHeroTop;

  function animatePosY() {
    const posY = Math.min(window.pageYOffset / centerHeroTop, 1);

    /// card-1
    heroCardOne.current.style.transform = `translate3d(${posY * 200}% , ${
      posY * 500
    }%, 0) rotate(${-16 + posY * 40}deg) scale(${1 - posY * 0.2})`;
    heroCardOne.current.style.opacity = `${1 - posY * posY}`;

    /// //card-2
    heroCardTwo.current.style.transform = `translate3d(${posY * 200}% , ${
      posY * 400
    }%, 0) rotate(${-30 + posY * 40}deg) scale(${1 - posY * 0.2})`;
    heroCardTwo.current.style.opacity = `${1 - posY * posY}`;

    /// ///card-3
    heroCardThree.current.style.transform = `translate3d(${posY * -200}% , ${
      posY * 400
    }%, 0) rotate(${16 - posY * 30}deg) scale(${1 - posY * 0.2})`;
    heroCardThree.current.style.opacity = `${1 - posY}`;
  }

  /// ////////////// //hero animation
  return function () {
    centerHeroTop =
      centerHeroCard.current?.offsetTop -
        centerHeroCard.current.offsetHeight / 4 || 0;

    /// /////////
    if (window.pageYOffset < window.innerHeight) {
      if (window.innerWidth > 767) {
        requestAnimationFrame(animatePosY);
      }
    }
  };
}

export function scrollFactory1({
  homeFeatureOne,
  homeFeatureTwo,
  homeFeatureThree,
  homeAsset1,
  homeAsset2,
  homeAsset3,
  asset1,
  asset2,
  asset3,
}) {
  let timer1;
  let timer2;
  let timer3;
  let timer4;
  let timer5;
  let timer6;
  return function () {
    /// ////////feature-asset
    if (timer1) clearTimeout(timer1);
    if (timer2) clearTimeout(timer2);
    if (timer3) clearTimeout(timer3);
    if (timer4) clearTimeout(timer4);
    if (timer5) clearTimeout(timer5);
    if (timer6) clearTimeout(timer6);
    if (window.innerWidth > 900) {
      if (
        window.pageYOffset + homeFeatureOne.current.offsetHeight * 2 <
        homeFeatureOne.current.offsetTop
      ) {
        asset1.stop();
      }
      if (
        window.pageYOffset + homeFeatureOne.current.offsetHeight * 2 >
          homeFeatureOne.current.offsetTop &&
        window.pageYOffset < homeFeatureOne.current.offsetTop
      ) {
        homeAsset1.current.classList.add('active');
        asset1.play();
        homeAsset2.current.classList.remove('active');
        timer1 = setTimeout(() => {
          asset2.stop();
        }, 400);
        homeAsset3.current.classList.remove('active');
        timer2 = setTimeout(() => {
          asset3.stop();
        }, 400);
      } else if (
        window.pageYOffset > homeFeatureOne.current.offsetTop &&
        window.pageYOffset < homeFeatureTwo.current.offsetTop
      ) {
        homeAsset1.current.classList.remove('active');
        timer3 = setTimeout(() => {
          asset1.stop();
        }, 400);
        homeAsset2.current.classList.add('active');
        asset2.play();
        homeAsset3.current.classList.remove('active');
        timer4 = setTimeout(() => {
          asset3.stop();
        }, 400);
      } else if (
        window.pageYOffset > homeFeatureTwo.current.offsetTop &&
        window.pageYOffset <
          homeFeatureThree.current.offsetTop +
            homeFeatureThree.current.offsetHeight
      ) {
        homeAsset1.current.classList.remove('active');
        timer5 = setTimeout(() => {
          asset1.stop();
        }, 400);
        homeAsset2.current.classList.remove('active');
        timer6 = setTimeout(() => {
          asset2.stop();
        }, 400);
        homeAsset3.current.classList.add('active');
        asset3.play();
      } else if (
        window.pageYOffset >
        homeFeatureThree.current.offsetTop +
          (homeFeatureThree.current.offsetHeight * 5) / 4
      ) {
        asset3.stop();
      }
    } else if (window.innerWidth < 767) {
      if (
        window.pageYOffset + homeFeatureOne.current.offsetHeight >
          homeFeatureOne.current.offsetTop &&
        window.pageYOffset <
          homeFeatureOne.current.offsetHeight + homeFeatureOne.current.offsetTop
      ) {
        asset1.play();
      } else if (
        window.pageYOffset + homeFeatureOne.current.offsetHeight <
          homeFeatureOne.current.offsetTop ||
        window.pageYOffset >
          homeFeatureOne.current.offsetHeight + homeFeatureOne.current.offsetTop
      ) {
        asset1.stop();
      }
      if (
        window.pageYOffset + homeFeatureTwo.current.offsetHeight >
          homeFeatureTwo.current.offsetTop &&
        window.pageYOffset <
          homeFeatureTwo.current.offsetHeight + homeFeatureTwo.current.offsetTop
      ) {
        asset2.play();
      } else if (
        window.pageYOffset + homeFeatureTwo.current.offsetHeight <
          homeFeatureTwo.current.offsetTop ||
        window.pageYOffset >
          homeFeatureOne.current.offsetHeight + homeFeatureOne.current.offsetTop
      ) {
        asset2.stop();
      }
      if (
        window.pageYOffset + homeFeatureThree.current.offsetHeight >
          homeFeatureThree.current.offsetTop &&
        window.pageYOffset <
          homeFeatureThree.current.offsetHeight +
            homeFeatureThree.current.offsetTop
      ) {
        asset3.play();
      } else if (
        window.pageYOffset + homeFeatureThree.current.offsetHeight <
          homeFeatureThree.current.offsetTop ||
        window.pageYOffset >
          homeFeatureOne.current.offsetHeight + homeFeatureOne.current.offsetTop
      ) {
        asset3.stop();
      }
    }
  };
}
