import React from 'react';
import PropTypes from 'prop-types';

import playBtn from '../../images/play-btn.svg';

import './CarouselItem.scss';

const CarouselItem = ({
  name,
  title,
  text = '',
  image,
  video,
  videoThumb,
  toggleModal,
}) => (
  <div
    className={`carousel-item flex flex-col justify-space-between rounded ${
      video ? 'video-item' : ' '
    }`}>
    {video ? (
      <img loading="lazy" src={videoThumb} className="video" alt="" />
    ) : (
      <p className="text">{text}</p>
    )}
    {video && (
      <button type="button" onClick={() => toggleModal(video)}>
        <img loading="lazy" src={playBtn} className="play-btn" alt="" />
      </button>
    )}
    <div className={`person flex ${video ? 'is-video' : ' '}`}>
      {image && <img loading="lazy" src={image} className="image" alt="" />}
      <div className="details">
        <p className="name">{name}</p>
        <div className="title">{title}</div>
      </div>
    </div>
  </div>
);
CarouselItem.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  image: PropTypes.string,
  video: PropTypes.string,
  videoThumb: PropTypes.string,
  toggleModal: PropTypes.func,
};

export default CarouselItem;
