import React, { useState, useCallback, useEffect } from 'react';
// import { tns } from 'tiny-slider/src/tiny-slider';

import CarouselItem from '../CarouselItem/CarouselItem';
import './Carousel.scss';
import './tiny-slider.css';
import Modal from '../Modal/Modal';
import ishaPatel from '../../images/isha-patel.jpg';
import pulkitNarwani from '../../images/pulkit-narwani.jpg';

const Carousel = () => {
  const [modal, setModal] = useState('');
  const toggleModal = useCallback((index) => {
    setModal((state) => (index === state ? '' : index));
  }, []);

  useEffect(() => {
    document.querySelector('body').classList.toggle('overflow-hidden', modal);
  }, [modal]);

  // const mySlider = useRef();

  useEffect(() => {
    const options = {
      container: '.my-slider',
      items: 1.2,
      gutter: 15,
      loop: false,
      mouseDrag: true,
      controls: false,
      navPosition: 'bottom',
      preventScrollOnTouch: 'auto',
      responsive: {
        767: {
          items: 2,
          controls: false,
          nav: true,
        },
        900: {
          items: 3,
          controls: false,
          nav: true,
        },
        1200: {
          items: 3,
          controls: true,
          nav: false,
        },
      },
    };
    import('tiny-slider').then(({ tns }) => {
      tns(options);
    });
  }, []);

  return (
    <>
      <Modal modal={modal} toggleModal={toggleModal} />
      <div className="carousel">
        <div className="my-slider inner-div equal-height">
          <div>
            <CarouselItem
              name="Isha Patel"
              title="Co-founder, Kale"
              video="/videos/isha-patel.mp4"
              videoThumb={ishaPatel}
              image="/img/isha-patel-thumb.jpg"
              toggleModal={toggleModal}
            />
          </div>
          <div>
            <CarouselItem
              name="Yamini Sharma"
              title="Product Marketing Lead at Jupiter"
              image="/img/yamini-sharma.jpg"
              text="Busyness gives you myopia. If you’re not careful, you can keep prioritizing reactive work and meetings. Aerotime consistently reminds me to work on big problems by moving meetings around and creating chunks of focus time."
            />
          </div>
          <div>
            <CarouselItem
              name="Pulkit Narwani"
              title="Software Engineer, Coinbase"
              video="/videos/pulkit-narwani.mp4"
              videoThumb={pulkitNarwani}
              image="/img/pulkit-narwani-thumb.jpg"
              toggleModal={toggleModal}
            />
          </div>
          <div>
            <CarouselItem
              name="Ali Abramovitz"
              title="Co-Founder of Chorus Meditation"
              image="/img/ali-abramovitz.jpg"
              text="Aerotime has improved my productivity by allowing me longer time to focus on one task at a time. I don’t have to worry about checking when my next call is and if I’ll be late because I know it isn’t tor a few hours so I can peacefully focus."
            />
          </div>
          <div>
            <CarouselItem
              name="Gopal Venkatesan"
              title="Sr Staff Software Engineer at LinkedIn"
              image="/img/gopal-venkatesan.jpeg"
              text="In a horizontal role, getting bigger chunks of continuous focus times is a luxury. Aerotime does exactly that by scheduling or moving meetings in a way that cuts down context switching and small unusable blocks between meetings."
            />
          </div>
          <div>
            <CarouselItem
              name="Abhishek Kankani"
              title="CEO at Dyte"
              image="/img/abhishek-kankani.jpg"
              text="As a founder the most important thing for me is to be in control of my own time and minimize context switching. Aerotime has helped me achieve this and also helped carve out time for me to purely focus on myself. Love the product!"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Carousel;
