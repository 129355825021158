/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeAsyncScriptLoader from 'react-async-script';

import { Button } from '../Button/Button';
import { scrollFactory1 } from '../../js/scroll';
import useMediaQuery from '../../hooks/useMediaQuery';
import useElementOnScreen from '../../hooks/useElementOnScreen';

import circles from '../../images/circles.svg';
import path from '../../images/path.svg';
import battery from '../../images/battery.svg';
import coffee from '../../images/coffee.svg';
import smiley from '../../images/smiley.svg';
import './FullpageScroll.scss';

const FullpageScroll = ({ lottie }) => {
  const homeFeatureOne = useRef();
  const homeFeatureTwo = useRef();
  const homeFeatureThree = useRef();
  const homeAsset1 = useRef();
  const homeAsset2 = useRef();
  const homeAsset3 = useRef();
  const homeAsset1mob = useRef();
  const homeAsset2mob = useRef();
  const homeAsset3mob = useRef();

  const isMobile = useMediaQuery('(max-width: 900px)');

  const [scrollRef, isVisible] = useElementOnScreen();
  const [visibleOnce, setVisibleOnce] = useState(false);

  useEffect(() => {
    if (isVisible) setVisibleOnce(isVisible);
  }, [isVisible]);

  useEffect(() => {
    if (lottie && visibleOnce) {
      const asset1 = window.lottie.loadAnimation({
        container: isMobile ? homeAsset1mob.current : homeAsset1.current,
        path: '/lottie/1.json',
        autoplay: true,
        loop: true,
        renderer: 'svg',
      });
      const asset2 = window.lottie.loadAnimation({
        container: isMobile ? homeAsset2mob.current : homeAsset2.current,
        path: '/lottie/2.json',
        autoplay: true,
        loop: true,
        renderer: 'svg',
      });
      const asset3 = window.lottie.loadAnimation({
        container: isMobile ? homeAsset3mob.current : homeAsset3.current,
        path: '/lottie/3.json',
        autoplay: true,
        loop: true,
        renderer: 'svg',
      });

      const scrollConsole = scrollFactory1({
        homeFeatureOne,
        homeFeatureTwo,
        homeFeatureThree,
        homeAsset1,
        homeAsset2,
        homeAsset3,
        asset1,
        asset2,
        asset3,
      });
      window.addEventListener('scroll', scrollConsole, true);

      return () => {
        window.removeEventListener('scroll', scrollConsole, true);
        asset1.destroy();
        asset2.destroy();
        asset3.destroy();
      };
    }
    return () => {};
  }, [lottie, isMobile, visibleOnce]);

  return (
    <div className="fullpage-scroll container" id="streamline" ref={scrollRef}>
      <div className="fixed col col-6">
        <h1 className="heading">
          Keep your calendar
          <span className="text-gradient"> decluttered</span>
        </h1>
        <div className="imageContainer hidden-mobile">
          <div className="video active" ref={homeAsset1}></div>
          <div className="video" ref={homeAsset2}></div>
          <div className="video" ref={homeAsset3}></div>
          <img loading="lazy" className="circles" src={circles} alt="" />
          <img loading="lazy" className="path" src={path} alt="" />
        </div>
      </div>
      <div className="scrolling col col-6">
        <div className="section" ref={homeFeatureOne}>
          <div className="section-data">
            <div className="section-header">
              <img loading="lazy" className="icon" src={battery} alt="" />
              <h3 className="sub-heading">Minimize scattered meetings</h3>
            </div>
            <p>
              Aerotime can move meetings on your behalf and stack them to each
              other, removing calendar fragmentation.
            </p>
            <Button
              type="primary"
              text="Read more"
              size="md"
              url="https://help.aerotime.com/en/articles/5952399-flexible-meetings-minimize-scattered-meetings"
            />
            <div className="imageContainer visible-mobile">
              <div
                className="mobile-image"
                style={{ height: 246 }}
                ref={homeAsset1mob}
              />
              <img loading="lazy" className="circles" src={circles} alt="" />
              <img loading="lazy" className="path" src={path} alt="" />
            </div>
          </div>
        </div>
        <div className="section" ref={homeFeatureTwo}>
          <div className="section-data">
            <div className="section-header">
              <img loading="lazy" className="icon" src={coffee} alt="" />
              <h3 className="sub-heading">Create healthy new meetings</h3>
            </div>
            <p>
              Timeslot recommendations to keep you and your team in the flow.
            </p>
            <p>Agenda recommendations to keep your meetings productive.</p>
            <Button
              type="primary"
              text="Read more"
              size="md"
              url="https://help.aerotime.com/en/articles/5952467-creating-new-meetings-with-aerotime"
            />
            <div className="imageContainer visible-mobile">
              <div
                className="mobile-image"
                ref={homeAsset2mob}
                style={{ height: 236 }}
              />
              <img loading="lazy" className="circles" src={circles} alt="" />
              <img loading="lazy" className="path" src={path} alt="" />
            </div>
          </div>
        </div>
        <div className="section" ref={homeFeatureThree}>
          <div className="section-data">
            <div className="section-header">
              <img loading="lazy" className="icon" src={smiley} alt="" />
              <h3 className="sub-heading">Handle incoming meetings better</h3>
            </div>
            <p>
              Propose better timeslots for meetings that make all attendees more
              productive.
            </p>
            <p>Send quick replies that help in declining meetings with ease.</p>
            <Button
              type="primary"
              text="Read more"
              size="md"
              url="https://help.aerotime.com/en/articles/5952513-proposal-flows-handle-incoming-meetings-better"
            />
            <div className="imageContainer visible-mobile">
              <div
                className="mobile-image"
                ref={homeAsset3mob}
                style={{ height: 246 }}
              />
              <img loading="lazy" className="circles" src={circles} alt="" />
              <img loading="lazy" className="path" src={path} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FullpageScroll.defaultProps = {
  lottie: null,
};

FullpageScroll.propTypes = {
  lottie: PropTypes.shape({}),
};
// export default FullpageScroll;
export default makeAsyncScriptLoader('/lottie/lottie_light.min.js', {
  globalName: 'lottie',
})(FullpageScroll);
