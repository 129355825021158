import React from 'react';
import Tool from '../Tool/Tool';
import { Button } from '../Button/Button';
import scribble from '../../images/scribble.svg';
import './Tools.scss';

const Tools = () => (
  <div className="tools relative">
    <div className="relative container">
      <h1 className="heading">
        Connect with your <span className="text-gradient">favorite tools</span>
      </h1>
      <p className="sub-heading">
        Status updates and task integrations with tools you love and use
      </p>

      <div className="grid">
        <Tool
          name="Slack"
          description="Auto-snooze Slack and update status based on calendar. Create task right within Slack."
          image="/img/slack-icon.svg"
        />
        <Tool
          name="Gmail"
          description="Convert issues into tasks in Aerotime. Schedule them with ease and keep team in alignment."
          image="/img/gmail.svg"
        />
        <Tool
          name="Asana"
          comingSoon
          description="Convert any email into a task and schedule to reply on time."
          image="/img/asana-logo.svg"
        />
        {/* <Tool
          name="Linear"
          description="Link customer feedback to issues, and keep your development team in the loop"
          image="/img/linear-logo.svg"
          comingSoon
        /> */}
        <Tool
          name="Jira"
          comingSoon
          description="Convert Asana tickets to Aerotime tasks. Schedule them with ease."
          image="/img/jira.svg"
        />
        {/* <Tool
          name="Reminders"
          description="Convert Apple Reminders to Aerotime tasks."
          image="/img/apple-reminders.svg"
          comingSoon
        /> */}
      </div>
      <div className="wrap-btn">
        <Button
          size="lg"
          text="Explore Integrations"
          type="primary"
          url="https://help.aerotime.com/en/articles/6012733-existing-upcoming-integrations"
        />
      </div>
      <img loading="lazy" className="scribble" src={scribble} alt="" />
    </div>
  </div>
);

export default Tools;
