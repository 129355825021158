import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import './Articles.scss';

const Articles = ({ posts = [] }) => {
  useEffect(() => {
    const options = {
      container: '.my-slider1',
      items: 1.2,
      gutter: 15,
      loop: false,
      mouseDrag: true,
      controls: false,
      navPosition: 'bottom',
      preventScrollOnTouch: 'auto',
      responsive: {
        767: {
          items: 2,
          controls: false,
          nav: true,
        },
        900: {
          items: 3,
          controls: false,
          nav: true,
        },
      },
    };
    import('tiny-slider').then(({ tns }) => {
      tns(options);
    });
  }, []);

  return (
    <div className="articles container">
      <div className="flex justify-space-between align-center">
        <h1 className="heading">Posts from Aerotime</h1>
      </div>
      <div className="articleBox">
        <div className="my-slider1">
          {posts.map(({ node }) => (
            <div>
              <a key={node.id} href={node.url} className="article">
                <div className="article-img">
                  <img
                    loading="lazy"
                    className="image"
                    src={node?.og_image || node?.feature_image}
                    alt={node.title}
                  />
                </div>
                <p className="title">{node.title}</p>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

Articles.propTypes = {
  posts: PropTypes.array,
};

export default Articles;
