/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import PropTypes from 'prop-types';
import makeAsyncScriptLoader from 'react-async-script';
import { Button } from '../Button/Button';
import useMediaQuery from '../../hooks/useMediaQuery';
import scrollFactory from '../../js/scroll';

import './Hero.scss';

const Hero = ({ lottie }) => {
  const heroCardOne = useRef();
  const heroCardTwo = useRef();
  const heroCardThree = useRef();
  const centerHeroCard = useRef();
  const heroVideo = useRef();
  const imgPlaceholder = useRef();

  const isMobile = useMediaQuery('(max-width: 900px)');

  useEffect(() => {
    const scrollConsole = scrollFactory({
      centerHeroCard,
      heroCardOne,
      heroCardTwo,
      heroCardThree,
    });
    window.addEventListener('scroll', scrollConsole, true);

    return () => {
      window.removeEventListener('scroll', scrollConsole, true);
    };
  }, []);

  useEffect(() => {
    if (lottie) {
      const heroBanner = window.lottie.loadAnimation({
        container: heroVideo.current,
        path: '/lottie/hero-banner.json',
        autoplay: isMobile,
        loop: false,
        renderer: 'svg',
      });

      const scrollConsole = function () {
        if (isMobile) {
          window.pageYOffset >
          heroVideo.current?.offsetHeight + window.innerHeight / 2
            ? heroBanner.stop()
            : heroBanner.play();
        } else {
          window.pageYOffset >
            heroVideo.current?.offsetHeight + window.innerHeight / 2 ||
          window.pageYOffset < window.innerHeight / 4
            ? heroBanner.stop()
            : heroBanner.play();
        }
      };
      window.addEventListener('scroll', scrollConsole, true);

      heroBanner.addEventListener('DOMLoaded', () => {
        if (imgPlaceholder.current) {
          imgPlaceholder.current.classList.add('hidden');
        }
      });

      return () => {
        window.removeEventListener('scroll', scrollConsole, true);
        heroBanner.destroy();
      };
    }
  }, [lottie, isMobile, heroVideo]);

  return (
    <>
      <div className="hero container">
        <div className="card card-1 hidden-mobile" ref={heroCardOne}>
          <img
            loading="lazy"
            className="float"
            src="/img/slack-card.svg"
            alt="slack"
          />
        </div>
        <div className="card card-2 hidden-mobile" ref={heroCardTwo}>
          <img
            loading="lazy"
            className="float"
            src="/img/note.svg"
            alt="note"
          />
        </div>
        <h1 className="heading">
          Be more
          <span className="text-gradient"> effective </span>
          <br />
          and less busy
        </h1>
        <p className="sub-heading">
          Aerotime helps you work in flow state by streamlining your meetings
          and making it easier to prioritize tasks.
        </p>
        <div className="wrap" ref={centerHeroCard}>
          <Button
            size="xl"
            text="Get Started For Free"
            type="primary"
            url="https://app.aerotime.ai/"
          />
          <img
            loading="lazy"
            className="arrow"
            src="/img/arrow-curly.svg"
            alt=""
          />
        </div>
        <div className="card card-3 hidden-mobile" ref={heroCardThree}>
          <img loading="lazy" className="float" src="/img/asana.svg" alt="" />
        </div>
      </div>
      <div className="hero-section">
        <div className="app-mock container" ref={heroVideo}>
          <div ref={imgPlaceholder}>
            <StaticImage
              src="../../images/video-poster.jpg"
              className="video-poster"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
        <div className="brands container">
          <h2>Trusted by dynamic companies</h2>
          <div className="brand-images">
            <img
              loading="lazy"
              className="brand-image"
              src="/img/jupiter.svg"
              alt="jupiter"
              style={{ width: 115 }}
            />
            <img
              loading="lazy"
              className="brand-image"
              src="/img/fampay.svg"
              alt="fampay"
              style={{ width: 120 }}
            />
            <img
              loading="lazy"
              className="brand-image"
              src="/img/groww.svg"
              alt="groww"
              style={{ width: 127 }}
            />
            <img
              loading="lazy"
              className="brand-image"
              src="/img/nextdoor.svg"
              alt="nextdoor"
              style={{ width: 136 }}
            />
            <img
              loading="lazy"
              className="brand-image"
              src="/img/swiggy.svg"
              alt="swiggy"
              style={{ width: 120 }}
            />
            <img
              loading="lazy"
              className="brand-image"
              src="/img/razorpay.svg"
              alt="razorpay"
              style={{ width: 165 }}
            />
            <img
              loading="lazy"
              className="brand-image"
              src="/img/ycombinator.svg"
              alt="ycombinator"
              style={{ width: 145 }}
            />
            <StaticImage
              className="brand-image"
              src="../../images/nexus.png"
              alt="nexus"
              style={{ width: 95 }}
              loading="lazy"
            />
            <img
              loading="lazy"
              className="brand-image"
              src="/img/sequoia.svg"
              alt="sequoia"
              style={{ width: 148 }}
            />
            <img
              loading="lazy"
              className="brand-image hidden-mobile"
              src="/img/mighty-buildings.svg"
              alt="mighty-buildings"
              style={{ width: 116 }}
            />
            <img
              loading="lazy"
              className="brand-image hidden-mobile"
              src="/img/dyte.svg"
              alt="dyte"
              style={{ width: 100 }}
            />
            <img
              loading="lazy"
              className="brand-image hidden-mobile"
              src="/img/tigerhall.svg"
              alt="tigerhall"
              style={{ width: 114 }}
            />
          </div>
          <div className="wrap-info">
            <div className="empty"></div>
            <div className="fill">
              <div className="border"></div>
              <div className="backed-companies">Also backed by</div>
            </div>
          </div>
        </div>

        <img src="/img/wave-pattern.svg" alt="" className="wave-pattern" />
      </div>
    </>
  );
};

Hero.defaultProps = {
  lottie: null,
};

Hero.propTypes = {
  lottie: PropTypes.shape({}),
};
// export default Hero;
export default makeAsyncScriptLoader('/lottie/lottie_light.min.js', {
  globalName: 'lottie',
})(Hero);
