/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import makeAsyncScriptLoader from 'react-async-script';
import Accordion from '../AccordionNew/Accordion';
import useMediaQuery from '../../hooks/useMediaQuery';
import useElementOnScreen from '../../hooks/useElementOnScreen';

import { Button } from '../Button/Button';

import circles from '../../images/circles.svg';
import path from '../../images/path.svg';
import scribble from '../../images/scribble.svg';
import './Tasks.scss';
import { features } from '../../utils/contentProvider';

const Tasks = ({ lottie }) => {
  const refs = useRef([]);
  const accordionRef = useRef();
  // const centralize = useRef();

  const isMobile = useMediaQuery('(max-width: 900px)');

  const [featureIndex, setFeatureIndex] = useState(2);
  const [scrollStart, setScrollStart] = useState(false);

  const featureAutoPlay = useCallback(() => {
    setFeatureIndex((state) => (state === 2 ? 0 : state + 1));
    // homeAsset4.current.pause();
    // homeAsset4.current.currentTime = 0;
    // homeAsset5.current.pause();
    // homeAsset5.current.currentTime = 0;
    // homeAsset6.current.pause();
    // homeAsset6.current.currentTime = 0;
  }, []);

  useEffect(() => {
    if (scrollStart) {
      const timeCount = [12000, 7000, 9000];
      const interval = setTimeout(() => {
        featureAutoPlay();
      }, timeCount[featureIndex]);

      return () => {
        clearInterval(interval);
      };
    }
  }, [featureAutoPlay, featureIndex, scrollStart]);

  useEffect(() => {
    function scrollFromTop() {
      if (
        window.pageYOffset >
          centralize.current?.offsetTop -
            centralize.current?.offsetHeight / 1.8 &&
        window.innerWidth > 1599
      ) {
        console.log(0);
        setFeatureIndex(0);
        setScrollStart(true);
        window.removeEventListener('scroll', scrollFromTop);
        // window.addEventListener('scroll', scrollFromBottom);
      } else if (
        window.pageYOffset >
          centralize.current?.offsetTop -
            centralize.current?.offsetHeight / 2.5 &&
        window.innerWidth > 990
      ) {
        console.log(0);
        setFeatureIndex(0);
        setScrollStart(true);
        window.removeEventListener('scroll', scrollFromTop);
        // window.addEventListener('scroll', scrollFromBottom);
      } else if (
        window.pageYOffset >
          centralize.current?.offsetTop -
            centralize.current?.offsetHeight / 1.2 &&
        window.innerWidth > 600
      ) {
        console.log(0);
        setFeatureIndex(0);
        setScrollStart(true);
        window.removeEventListener('scroll', scrollFromTop);
        // window.addEventListener('scroll', scrollFromBottom);
      } else if (
        window.pageYOffset >
        centralize.current?.offsetTop - centralize.current?.offsetHeight / 2.5
      ) {
        console.log(0);
        setFeatureIndex(0);
        setScrollStart(true);
        window.removeEventListener('scroll', scrollFromTop);
        // window.addEventListener('scroll', scrollFromBottom);
      }
    }

    // function scrollFromBottom() {
    //   if (
    //     window.pageYOffset <
    //     centralize.current?.offsetTop - centralize.current?.offsetHeight / 2
    //   ) {
    //     window.removeEventListener('scroll', scrollFromBottom);
    //     window.addEventListener('scroll', scrollFromTop);
    //   }
    // }

    window.addEventListener('scroll', scrollFromTop);

    return () => {
      window.removeEventListener('scroll', scrollFromTop);
    };
  }, []);

  const [centralize, isVisible] = useElementOnScreen();
  const [visibleOnce, setVisibleOnce] = useState(false);

  useEffect(() => {
    if (isVisible) setVisibleOnce(isVisible);
  }, [isVisible]);

  useEffect(() => {
    if (lottie && visibleOnce) {
      const accordion1 = window.lottie.loadAnimation({
        container: isMobile ? refs.current[3] : refs.current[0],
        path: '/lottie/4.json',
        autoplay: true,
        loop: true,
        renderer: 'svg',
      });
      const accordion2 = window.lottie.loadAnimation({
        container: isMobile ? refs.current[4] : refs.current[1],
        path: '/lottie/5.json',
        autoplay: false,
        loop: true,
        renderer: 'svg',
      });
      const accordion3 = window.lottie.loadAnimation({
        container: isMobile ? refs.current[5] : refs.current[2],
        path: '/lottie/6.json',
        autoplay: false,
        loop: true,
        renderer: 'svg',
      });
      accordionRef.current = [accordion1, accordion2, accordion3];

      return () => {
        accordion1.destroy();
        accordion2.destroy();
        accordion3.destroy();
      };
    }
  }, [lottie, isMobile, visibleOnce]);

  useEffect(() => {
    const timer = [];
    accordionRef?.current?.forEach((e, i) => {
      if (i !== featureIndex) {
        timer[i] = setTimeout(() => {
          e.stop();
        }, 1000);
      }
    });
    if (accordionRef.current) accordionRef.current[featureIndex].play();
    return () => {
      timer.forEach((t) => clearTimeout(t));
    };
  }, [featureIndex, accordionRef]);

  return (
    <div className="tasks relative" id="centralize" ref={centralize}>
      <img
        loading="lazy"
        src="/img/wave-pattern-up.svg"
        alt=""
        className="wave-pattern wave-pattern-up"
      />
      <div className="relative container">
        <h1 className="heading">
          Centralize and prioritize <br /> tasks
          <span className="text-gradient"> with ease</span>
        </h1>
        <div className="flex justify-space-between">
          <div className="col col-6 accordion-section">
            <div className="help-home-accordions">
              <Accordion
                className="accordion"
                selectedIndex={featureIndex}
                onChange={setFeatureIndex}>
                <div
                  data-img="/img/check-circle.svg"
                  data-header="Instant task capture">
                  <p>
                    Global shortcuts to capture tasks anywhere and integrations
                    with your favourite apps (like Slack) to bring tasks in one
                    place.
                  </p>
                  <div
                    className="video visible-mobile"
                    ref={(element) => {
                      refs.current[3] = element;
                    }}></div>
                </div>
                <div
                  data-img="/img/calender-icon.svg"
                  data-header="Plan and schedule tasks in one drag">
                  <p>
                    Prioritize and commit to your important tasks by scheduling
                    them on your calendar.
                    <br />
                    &nbsp;
                  </p>
                  <div
                    className="video visible-mobile"
                    ref={(element) => {
                      refs.current[4] = element;
                    }}></div>
                </div>
                <div
                  data-img="/img/headphones.svg"
                  data-header="Simplify balance of collaboration and deep work">
                  <p>
                    Aerotime keeps your calendar available for meetings even
                    when tasks are there, but when you hit a busy threshold it
                    shields your time.
                  </p>
                  <div
                    className="video visible-mobile"
                    ref={(element) => {
                      refs.current[5] = element;
                    }}></div>
                </div>
              </Accordion>
            </div>
            <div className="hidden-mobile">
              <Button
                size="md"
                text="Get Started"
                type="primary"
                url="https://app.aerotime.ai/"
              />
            </div>
          </div>
          <div className="col col-6 hidden-mobile">
            <div className="videoContainer ">
              {/* <video
              className={`video ${showContent === 0 ? 'active' : ''}`}
              src="/videos/4.webm"
              autoPlay
              loop
              muted
              playsInline
              preload="none"
            />
            <video
              className={`video ${showContent === 1 ? 'active' : ''}`}
              src="/videos/5.webm"
              autoPlay
              loop
              muted
              playsInline
              preload="none"
            />
            <video
              className={`video ${showContent === 2 ? 'active' : ''}`}
              src="/videos/6.webm"
              autoPlay
              loop
              muted
              playsInline
              preload="none"
            /> */}

              <div className="help-img">
                {features.map(({ id }) => (
                  <div
                    key={id}
                    className={`video ${id === featureIndex ? 'active' : ''}`}
                    ref={(element) => {
                      refs.current[id] = element;
                    }}></div>
                ))}
              </div>
              <img loading="lazy" className="circles" src={circles} alt="" />
              <img loading="lazy" className="path" src={path} alt="" />
            </div>
          </div>
          <img
            loading="lazy"
            className="scribble hidden-mobile"
            src={scribble}
            alt=""
          />
        </div>
      </div>

      <img
        loading="lazy"
        src="/img/wave-pattern-up.svg"
        alt=""
        className="wave-pattern wave-pattern-up"
      />
    </div>
  );
};

Tasks.defaultProps = {
  lottie: null,
};

Tasks.propTypes = {
  lottie: PropTypes.shape({}),
};
// export default Tasks;
export default makeAsyncScriptLoader('/lottie/lottie_light.min.js', {
  globalName: 'lottie',
})(Tasks);
