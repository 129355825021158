import React from 'react';
import { Button } from '../Button/Button';

import './Info.scss';

const Info = () => (
  <div className="info container flex justify-center text-center">
    <div className="col col-6">
      <h1>Manage your time effectively, easily.</h1>
      <p>Declutter, prioritize, zone-in. Repeat.</p>
      <Button
        size="xl"
        text="Get Started"
        type="primary"
        url="https://app.aerotime.ai/"
      />
    </div>
  </div>
);

export default Info;
