import React from 'react';
import PropTypes from 'prop-types';

import './Tool.scss';

const Tool = ({ name, description, image, comingSoon }) => (
  <div className="tool flex rounded">
    <img loading="lazy" src={image} alt="" />
    <div className="details">
      <div className="flex align-center">
        <h2 className="name">{name}</h2>
        {comingSoon && <span className="coming-soon">Coming Soon</span>}
      </div>
      <p className="description">{description}</p>
    </div>
  </div>
);

Tool.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  comingSoon: PropTypes.bool,
};

export default Tool;
