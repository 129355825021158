/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import './Modal.scss';
// import playBtn from '../../images/play-btn.svg';
// import pauseBtn from '../../images/pause-btn.svg';

const Modal = ({ modal, toggleModal }) => {
  const vidRef = useRef();
  // const btnRef = useRef();

  // const videoPlay = useCallback(() => {
  //   vidRef.current.play();
  //   btnRef.current.classList.add('hide');
  // }, [vidRef]);

  // const videoPause = useCallback(() => {
  //   vidRef.current.pause();
  //   btnRef.current.classList.remove('hide');
  // }, [vidRef]);

  const escapeClose = useCallback((event) => {
    if (event.key === 'Escape') {
      toggleModal('');
    }
  }, []);

  useEffect(() => {
    if (modal) {
      window.addEventListener('keydown', escapeClose);

      return () => {
        window.removeEventListener('keydown', escapeClose);
      };
    }
  }, [modal]);

  return (
    <div className={`modal ${modal ? 'modal-open' : ''}`}>
      <button
        type="button"
        onClick={() => toggleModal('')}
        className="close-btn"></button>
      <div className="inner-modal">
        {modal ? (
          <>
            <video ref={vidRef} src={modal} autoPlay controls playsInline />
            {/* <div ref={btnRef} className="button-wrapper">
              <button type="button" className="play-btn" onClick={videoPlay}>
                <img src={playBtn} alt="" />
              </button>
              <button type="button" className="pause-btn" onClick={videoPause}>
                <img src={pauseBtn} alt="" />
              </button>
            </div> */}
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

Modal.propTypes = {
  modal: PropTypes.string,
  toggleModal: PropTypes.func,
};

export default Modal;
