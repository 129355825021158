import React, { useRef, useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import makeAsyncScriptLoader from 'react-async-script';
import useElementOnScreen from '../../hooks/useElementOnScreen';
// import HoverCard from '../HoverCard/HoverCard';

import arrow from '../../images/arrow-curly.svg';
import './Focus.scss';

const Focus = ({ lottie }) => {
  const spotifyRef = useRef();

  const [focusRef, isVisible] = useElementOnScreen();
  const [visibleOnce, setVisibleOnce] = useState(false);

  useEffect(() => {
    if (isVisible) setVisibleOnce(isVisible);
  }, [isVisible]);

  useEffect(() => {
    if (lottie && visibleOnce) {
      const spotify = window.lottie.loadAnimation({
        container: spotifyRef.current,
        path: '/lottie/spotify.json',
        autoplay: true,
        loop: true,
        renderer: 'svg',
      });

      return () => {
        spotify.destroy();
      };
    }
    return () => {};
  }, [lottie, visibleOnce]);
  return (
    <div className="focus" id="zone" ref={focusRef}>
      <div className="focus-top container">
        <div className="col col-6 text-center">
          <StaticImage
            src="../../images/meditate.jpg"
            alt=""
            className="meditate"
            loading="lazy"
          />
        </div>
        <div className="focus-content col col-6 flex flex-col justify-center">
          <h2 className="text-gradient">Be in the zone.</h2>
          <h2>Stay focused with ease.</h2>
          <img
            loading="lazy"
            className="arrow hidden-mobile"
            src={arrow}
            alt=""
          />
        </div>
      </div>
      <div className="cards container">
        <a
          href="https://help.aerotime.com/en/articles/5953545-slack-aerotime"
          className="hover-card col col-4"
          target="_blank"
          rel="noopener noreferrer">
          <div>
            <h2 className="title">Snooze distractions</h2>
            <div className="content">
              <p>
                Configure Slack to snooze notifications when you're in focus
              </p>
              <p>
                Your team will know what you're busy with (based on your privacy
                settings).
              </p>
            </div>
          </div>
          <div className="image-wrap justify-center">
            <div className="first-image">
              <StaticImage
                className="focus-distractions"
                src="../../images/focus-distractions.png"
                alt=""
                loading="lazy"
              />
              <img
                loading="lazy"
                className="focus-slack"
                src="/img/slack.svg"
                alt=""
              />
              <img
                loading="lazy"
                className="in-focus-time"
                src="/img/in-focus-time.svg"
                alt=""
              />
            </div>
          </div>
        </a>
        <a
          href="https://help.aerotime.com/en/articles/5953562-focus-time-shield-your-time-for-what-s-important"
          className="hover-card col col-4"
          target="_blank"
          rel="noopener noreferrer">
          <div>
            <h2 className="title">Proactive focus blocks</h2>
            <div className="content">
              <p>
                Block preferred timeslots in advance to keep your calendar
                available for deep work.
              </p>
            </div>
          </div>
          <div className="image-wrap justify-flex-end second-image">
            <img loading="lazy" src="/img/focus-time.svg" alt="" />
          </div>
        </a>

        <a
          href="https://help.aerotime.com/en/articles/5953573-focus-music-minimize-internal-distractions"
          className="hover-card col col-4"
          target="_blank"
          rel="noopener noreferrer">
          <div>
            <h2 className="title">Focus Music</h2>
            <div className="content">
              <p>Fresh playlists every week for different tasks.</p>
              <p>
                Lyric-free music for deep work and the latest coffee shop music
                for everything else.
              </p>
            </div>
          </div>
          <div className="image-wrap justify-flex-end">
            <div className="spotify" ref={spotifyRef} />
          </div>
        </a>

        {/* <HoverCard title="Proactive focus blocks" image={focusDistractions}>
        <p>
          Block preferred timeslots in advance to keep your calendar available
          for deep work.
        </p>
      </HoverCard>
      <HoverCard title="Focus" image={focusMusic}>
        <p>Fresh playlists every week for different tasks</p>

        <p>
          Lyric free music for deep work and the latest coffee shop music for
          everything else.
        </p>
      </HoverCard> */}
      </div>
    </div>
  );
};

Focus.defaultProps = {
  lottie: null,
};

Focus.propTypes = {
  lottie: PropTypes.shape({}),
};
// export default Focus;
export default makeAsyncScriptLoader('/lottie/lottie_light.min.js', {
  globalName: 'lottie',
})(Focus);
