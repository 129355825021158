import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './Metrics.scss';

const Metrics = () => (
  <div className="metrics container">
    <div className="flex col col-7">
      <div className="col col-6">
        <h1 className="display">
          20<span>%</span>
        </h1>
        <div className="info-text">
          <span className="text">Extra time generated </span>
          within your working hours.
        </div>
      </div>
      <div className="col col-6">
        <h1 className="display">
          60<span>%</span>
        </h1>
        <div className="info-text">
          <span className="text">More work done per week </span>
          due to better prioritization
        </div>
      </div>
    </div>
    <div className="col col-5 text-center">
      <StaticImage
        className="cyclist"
        src="../../images/stats-illustration.jpg"
        alt=""
        loading="lazy"
      />
    </div>
  </div>
);

export default Metrics;
