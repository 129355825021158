import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from '../Button/Button';
import './CTA.scss';

const CTA = () => (
  <div className="cta container text-center">
    <StaticImage
      className="fly hidden-mobile"
      src="../../images/footer-illustration.jpg"
      alt=""
      loading="lazy"
    />
    <StaticImage
      className="fly visible-mobile"
      src="../../images/footer-illustration-mob.jpg"
      alt=""
      loading="lazy"
    />
    <div className="wrap">
      <h1 className="title text-gradient">Declutter, prioritize, zone-in.</h1>
      <h1>Repeat.</h1>
    </div>
    <Button
      text="Get started with Aerotime"
      size="lg"
      type="primary"
      url="https://app.aerotime.ai/"
    />
  </div>
);

export default CTA;
