import React from 'react';
import { Link } from 'gatsby';
import Carousel from '../Carousel/Carousel';

import arrow from '../../images/arrow.svg';
import './Testimonials.scss';

const Testimonials = () => (
  <div className="testimonials container">
    <div className="wrapper hidden">
      <div className="case-study rounded">
        <Link className="link" to="/">
          Read the case study <img loading="lazy" src={arrow} alt="" />
        </Link>
      </div>
      <div className="asana rounded">
        <img
          loading="lazy"
          src="/img/asana.png"
          className="asana-logo"
          alt=""
        />
        <p className="text">
          This is Gem I really love it. If you know Project Huddle, you
          shouldn&apos;t let it go! This tool is fast for collecting, managing,
          and previewing design feedback on live websites.
        </p>
        <p className="name">Mario Cummings</p>
        <p className="title">Brand Design Manager at IBM</p>
      </div>
    </div>
    <Carousel />
  </div>
);

export default Testimonials;
